import styled from "styled-components";
import Logo from "./Logo";
import QuickNav from "./QuickNav";
import {useAppStore} from "../../providers/RootStoreProvider";
import {observer} from "mobx-react-lite";
import moment from "moment";

const NavBar = observer(() => {
    const { user, getRoom } = useAppStore()

    let room

    if (user?.waiting_for?.room.id) {
        room = getRoom(user.waiting_for.room.id)
    }

    return <NavBarStyled>
        <Logo />
        {user?.hasRole('STAFF') && <StaffButton>Staff</StaffButton>}
        {user?.hasRole('ADMIN') && <AdminButton>Admin</AdminButton>}
        {user?.hasRole('STUDENT') && <StudentButton>STUDENT</StudentButton>}
        {user?.waiting_for && <WaitingFor>
            <WaitingForLabel>Waiting for</WaitingForLabel>
            <EventDate>{moment(user.waiting_for.startAt).format('HH:mm')} &gt; {moment(user.waiting_for.endAt).format('HH:mm')}</EventDate>
            <EventName>{user.waiting_for.name}</EventName>
            {room && <RoomName className={room.type}>{room.name}</RoomName>}
        </WaitingFor>}
        <QuickNav />
    </NavBarStyled>
})

const NavBarStyled = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 20px;
    background-color: #fff;
    display: flex;
    height: 70px;
    color: #263e8e;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px;
`
const StaffButton = styled.div`
    background-color: var(--staff-room);
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
`

const AdminButton = styled.div`
    background-color: var(--technical-color);
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    border-radius: 5px;
`

const StudentButton = styled.div`
    background-color: #fff;
    padding: 10px 25px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: #000;
    border: 1px solid #000;
    border-radius: 5px;
`

const WaitingFor = styled.div`
    position: fixed;
    height: 65px;
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-gap: 0;
    top: 0;
    left: 250px;
    //background-color: #fff;
    //border-radius: 0 0 8px 8px;
    padding: 0 8px 5px 8px;
    //box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px;
    background-color: #263e8e;
    color: #fff;
`

const WaitingForLabel = styled.div`    
    font-size: 14px;
    margin-bottom: 5px;
    background-color: #fff;
    border-radius: 0 0 5px 5px;
    color: var(--sas-room);
    display: flex;
    align-items: center;
    margin-right: 100px;
    justify-content: center;
`

const EventDate = styled.div`
    font-style: italic;
    padding: 0;
    font-size: 15px;
`

const EventName = styled.div`
    font-weight: 600;
    padding: 0;
    font-size: 15px;
`

const RoomName = styled.div`
    display: flex;
    align-items: center;
    grid-column: 2;
    grid-row: 1 /span 3;
    color: #fff;    
    font-family: 'Roboto',sans-serif;
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    border-radius: 5px;
    margin: 15px;
    padding: 8px;
    
    background-color: #38DDD1;
    
    &.open-space {
        background-color: #ADF4EE;
    }
    
    &.sport {
        background-color: #CEA4FF;
    }
    &.cinema {
        background-color: #CEA4FF;
    }
    &.staff {        
        background-color: #6F6DF2;
    }
`


export default NavBar
