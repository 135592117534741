import Modal from "../global/Modal";
import MeetDemo from "../MeetDemo";
import {useEffect, useState} from "react";
import client from "../../helpers/api";
import Meeting from "./Meeting";
import {useAppStore} from "../../providers/RootStoreProvider";
import {Button} from "../global/common";
import Room from "../../models/Room";
import useVoter from "../../hooks/useVoter";
import styled from "styled-components";
import UserList from "../User/UserList";
import {observer} from "mobx-react-lite";

type Props = {
    room: Room
}

const VideoCall = observer(({room}: Props) => {
    const { user, usersLocation, getUsers, getWaitingUsers, closeDoor, openDoor, isInRoom } = useAppStore()

    const [ pip, setPip ] = useState(false);


    const togglePIP = () => {
        setPip(!pip)
    }


    return <Modal show={true} onClose={() => {}} pip={pip}>

        {/*{user && (user.hasRole("ROLE_STAFF") || room.isModerator(user)) && isInRoom(user.id, room.id) ? room.available ? <Button onClick={handleCloseDoor}>Close the door</Button> : <Button onClick={handleOpenDoor}>Open the door</Button> : ""}*/}

        <Meeting pip={pip} room={room} togglePIP={togglePIP}/>
    </Modal>
})


export default VideoCall
