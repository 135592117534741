import React, {useEffect, useState} from 'react';
import client from "../../../helpers/api";
import styled from "styled-components";
import {Button} from "../../global/common";

interface Log {
    date: string
    topic: string
    sign: string
    targets: number
    delivered: number
    valid: boolean
}

const SSE = () => {
    const [logs, setLogs] = useState<Array<Log>>([])
    const [filteredlogs, setFilteredLogs] = useState<Array<Log>>([])
    const [showAll, setShowAll] = useState<boolean>(true)

    useEffect(() => {
        handleLogs()
    }, [])

    const handleLogs = async () => {
        const response = await client.get<Array<Log>>('/debug/sse')
        setLogs(response.data)
        setFilteredLogs(response.data)
    }

    const handleShowAll = () => {
        setShowAll(!showAll)
    }

    useEffect(() => {
        if (showAll) {
            setFilteredLogs(logs)
        } else {
            setFilteredLogs(logs.filter((log => !log.valid)))
        }
    }, [showAll])

    return (
        <Page>
            <Header>
                <Title>Debug Page</Title>
                <Button className="button" onClick={handleShowAll}>{showAll ? "Show error only" : "Show all"}</Button>
            </Header>

            <LogTable>
                <thead>
                    <tr>
                    <th>Date</th>
                    <th>Topic</th>
                    <th>Sign</th>
                    <th>Targets</th>
                    <th>Delivered</th>
                    <th>Valid</th>
                </tr>
                </thead>

                <tbody>
                {filteredlogs.map(log => (
                    <LogLine className={log.valid ? 'log-valid' : 'log-invalid'}>
                        <td>{log.date}</td>
                        <td>{log.topic}</td>
                        <td>{log.sign}</td>
                        <td>{log.targets}</td>
                        <td>{log.delivered}</td>
                        <td>{log.valid ? 'Yes' : 'No'}</td>
                    </LogLine>
                ))}
                </tbody>
            </LogTable>
        </Page>

    );
};

const Page = styled.div`
    width: 1000px;
    margin: 0 auto;
`

const Header = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .button {
        margin: 0;
        background-color: #253e90;
    }
`

const Title = styled.h1`
    text-transform: uppercase;
    font-family: 'Roboto Slab',serif;
    font-weight: 500;
    color: #253e90;
`

const LogTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    font-size: 0.9em;
    font-family: sans-serif;
    min-width: 400px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

    thead tr {
        background-color: #253e90;
        color: #ffffff;
        text-align: left;
    }

    th, td {
        padding: 12px 15px;
    }

    tbody tr {
        border-bottom: 1px solid #dddddd;
    }

    tbody tr:nth-of-type(even) {
        background-color: #f3f3f3;
    }

    tbody tr:last-of-type {
        border-bottom: 2px solid #253e90;
    }
`

const LogLine = styled.tr`
    &.log-invalid {
        background-color: #fa6d6d !important;
    }
`

export default SSE;
