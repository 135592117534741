import {observer} from "mobx-react-lite";
import styled from "styled-components";
import {useAppStore} from "../../providers/RootStoreProvider";
import DropdownMenu from "./DropdownMenu";
import {Avatar, Button} from "../global/common";
import {Link} from "react-router-dom";

const UserMenu = observer(() => {
    const {user, setAuthenticated} = useAppStore()

    const button = <Avatar><img src={user?.picture} alt={user?.username}/></Avatar>



    return <DropdownMenu component={button}>
        <UserContainer>
            <Username>{user?.username}</Username>
            <div>{user?.title}</div>
            {user?.course && <div>Course: {user.course}</div>}

            <Button onClick={() => setAuthenticated(false)}>Logout</Button>
        </UserContainer>
    </DropdownMenu>
})

const Username = styled.div`
    padding: 15px 0;
    font-weight: 600;
`

const UserContainer = styled.div`
    background-color: #fff;
    width: 200px;
    padding: 0 15px;    
    position: absolute;        
    top: 72px;
    right: 0;
`

export default UserMenu
