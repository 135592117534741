// @ts-nocheck
import React, {useEffect, useState, useRef} from 'react';
import {JaaSMeeting} from "@jitsi/react-sdk";
import {useAppStore} from "../../providers/RootStoreProvider";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "../../helpers/fontawesome";
import client from "../../helpers/api";
import * as jose from "jose"
import Room from "../../models/Room";
import moment from "moment";
import {Button} from "../global/common";
import UserList from "../User/UserList";
import useVoter from "../../hooks/useVoter";
import {ENV_NAME} from "../../config/url";
import {AxiosError} from "axios";
import Timer from "./Timer";

type Props = {
    pip: boolean
    room: Room
    togglePIP: () => void
}

// @ts-ignore-all
const Meeting = ({jwt, pip, room, togglePIP}: Props) => {
    const apiRef = useRef();
    const { user, leave, usersLocation, getUsers, getWaitingUsers, closeDoor, openDoor, isInRoom, rooms, setJitsiApi } = useAppStore()
    const { roomVoter } = useVoter()
    const [ logItems, updateLog ] = useState([]);
    const [ isInit, setInit ] = useState<boolean>(false);
    const [ isModerator, setModerator ] = useState(false);
    const [ audioMuted, setAudioMuted ] = useState(false);
    const [ videoMuted, setVideoMuted ] = useState(false);
    const [ videoAvailable, setVideoAvailable ] = useState(false);
    const [ audioAvailable, setAudioAvailable ] = useState(false);
    const [ knockingParticipants, updateKnockingParticipants ] = useState([]);
    const [currentJwt, setJwt] = useState<string|null>(null)

    const handleCloseDoor = (e: any) => {
        e.stopPropagation()
        closeDoor(room)
    }

    const handleOpenDoor = (e: any) => {
        e.stopPropagation()
        openDoor(room)
    }

    const getJWT = async () => {
        if (isInRoom(user.id, room.id)) {
            try {
                const response = await client.get('/room/' + room.id)
                setJwt(response.data.token)
            } catch (e: AxiosError) {
                // console.log(e.message)
            }
        }
    }

    useEffect(() => {
        getJWT()
    }, [])

    useEffect(() => {
        if (currentJwt) {
            const data = jose.decodeJwt(currentJwt)
            setModerator(data.context.user.moderator)
        }
    }, [currentJwt])

    const buildButtons = () => {
        const defaultToolbarButtons = [
            'camera',
            'chat',
            'fullscreen',
            'hangup',
            'microphone',
            'raisehand',
            'tileview',
            'toggle-camera',
        ]

        if (isModerator) {
            defaultToolbarButtons.push('desktop')
        }

        return defaultToolbarButtons
    }



    const defaultConfig = {
         logging: {
            // Default log level for the app and lib-jitsi-meet.
            defaultLogLevel: 'error',
            // Option to disable LogCollector (which stores the logs on CallStats).
            disableLogCollector: true,
            // Individual loggers are customizable.
            loggers: {
                // The following are too verbose in their logging with the default level.
                'modules/RTC/TraceablePeerConnection.js': 'error',
                'modules/statistics/CallStats.js': 'error',
                'modules/xmpp/strophe.util.js': 'error',
            }
        },
        defaultLanguage: "fr",
        subject: 'lalalala',
        hideConferenceSubject: true,
        hideParticipantsStats: true,
        prejoinConfig: {
            enabled: false,
        },
        lobby: {
            autoKnock: true,
            enableChat: true
        },
        toolbarButtons: buildButtons(),
        disableInviteFunctions: true,
        disabledNotifications: [
            "notify.grantedTo",
            'notify.moderationStartedTitle',
            'notify.moderationStoppedTitle'
        ],
        disableModeratorIndicator: true,
        breakoutRooms: {
            hideAddRoomButton: true,
            hideAutoAssignButton: true,
            hideJoinRoomButton: true
        },
        remoteVideoMenu: {
            // Whether the remote video context menu to be rendered or not.
            disabled: true,
            // If set to true the 'Kick out' button will be disabled.
            disableKick: true,
            // If set to true the 'Grant moderator' button will be disabled.
            disableGrantModerator: true,
            // If set to true the 'Send private message' button will be disabled.
            disablePrivateChat: true,
        },
        startAudioOnly: !!room.currentEvent,
        startAudioMuted: 5,
        startWithAudioMuted: !!room.currentEvent,
        startWithVideoMuted: !!room.currentEvent,
        hideConferenceTimer: true,
        tileView: {
            // Whether tileview should be disabled.
            disabled: false,
            // The optimal number of tiles that are going to be shown in tile view. Depending on the screen size it may
            // not be possible to show the exact number of participants specified here.
            numberOfVisibleTiles: 25,
        },

        // securityUi: {
        //     hideLobbyButton: true,
        //     disableLobbyPassword: true,
        // },
    }

    const pipConfig = {
        defaultLanguage: "fr",
        subject: 'lalalala',
        hideConferenceSubject: true,
        prejoinConfig: {
            enabled: false,
        },
        lobby: {
            autoKnock: true,
            enableChat: true
        },
        toolbarButtons: [],
        disableInviteFunctions: true,
        breakoutRooms: {
            hideAddRoomButton: true,
            hideAutoAssignButton: true,
            hideJoinRoomButton: true
        },
        tileView: {
            disabled: true,
            numberOfVisibleTiles: 1,
        },
    }

    useEffect(() => {
        apiRef.current?.executeCommand('overwriteConfig', pip ? pipConfig : defaultConfig);
    }, [pip, isModerator])

    const printEventOutput = payload => {
        updateLog(items => [ ...items, JSON.stringify(payload) ]);
    };

    const handleAudioStatusChange = (payload, feature) => {
        if (payload.muted) {
            updateLog(items => [ ...items, `${feature} off` ])
        } else {
            updateLog(items => [ ...items, `${feature} on` ])
        }
    };

    const handleChatUpdates = payload => {
        if (payload.isOpen || !payload.unreadCount) {
            return;
        }
        apiRef.current.executeCommand('toggleChat');
        updateLog(items => [ ...items, `you have ${payload.unreadCount} unread messages` ])
    };

    const handleKnockingParticipant = payload => {
        updateLog(items => [ ...items, JSON.stringify(payload) ]);
        updateKnockingParticipants(participants => [ ...participants, payload?.participant ])
    };

    const handleHangup = () => {
        apiRef.current.executeCommand('hangup')
    }

    const toggleAudio = () => {
        apiRef.current.executeCommand('toggleAudio')
    }

    const toggleVideo = () => {
        apiRef.current.executeCommand('toggleVideo')
    }

    const handleToggleAudio = () => {
        apiRef.current.isAudioMuted().then(response => {
            setAudioMuted(response)
        })
    }

    const handleToggleVideo = () => {
        apiRef.current.isVideoMuted().then(response => {
            setVideoMuted(response)
        })
    }

    const handleVideoAvailability = (payload) => {
        setVideoAvailable(payload.available)
    }

    const handleAudioAvailability = (payload) => {
        setAudioAvailable(payload.available)
    }

    const handleModeratorLeft = async (payload) => {
        setInit(true)
        // apiRef.current.getRoomsInfo().then(data => {
        //     if (data.rooms.length > 0) {
        //         const room = data.rooms[0]
        //         console.log(room)
        //         room.participants.map(participant => {
        //             console.log(participant)
        //             console.log(participant.id)
        //
        //         })
        //     }
        // });

        await setTimeout(getJWT, 1000)
    }

    const handleJaaSIFrameRef = iframeRef => {
        iframeRef.style.height = '100%';
        iframeRef.style.width = '100%';
    };

    const handleApiReady = apiObj => {
        apiRef.current = apiObj;
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
        apiRef.current.on('raiseHandUpdated', printEventOutput);
        apiRef.current.on('titleViewChanged', printEventOutput);
        apiRef.current.on('chatUpdated', handleChatUpdates);
        apiRef.current.on('knockingParticipant', handleKnockingParticipant);
        apiRef.current.on('audioMuteStatusChanged', handleToggleAudio);
        apiRef.current.on('videoMuteStatusChanged', handleToggleVideo);
        apiRef.current.on('videoAvailabilityChanged', payload => handleVideoAvailability(payload));
        apiRef.current.on('audioAvailabilityChanged', payload => handleAudioAvailability(payload));
        apiRef.current.on('participantLeft', payload => handleModeratorLeft(payload));
        apiRef.current.on('participantJoined', payload => handleModeratorLeft(payload));
        setJitsiApi(apiObj)
        let timeout = setTimeout(() => {
            setInit(true)
        }, 2000)
    };

    const handleReadyToClose = () => {
        setInit(false)
        leave()
    };

    const callDuration = <Timer startAt={room.callStartAt} />

    const renderButtons = () => (
        <div style = {{ margin: '15px 0' }}>
            <MicroButtonContainer>
                {audioAvailable && <MicroButton
                    onClick = { () => toggleAudio() }>
                    <FontAwesomeIcon icon={solid(!audioMuted ? 'microphone' : 'microphone-slash')} />
                </MicroButton>}
                {videoAvailable && <MicroButton
                    onClick = { () => toggleVideo() }>
                    <FontAwesomeIcon icon={solid(!videoMuted ? 'video' : 'video-slash')} />
                </MicroButton>}
                {/*<MicroButton*/}
                {/*    title = 'Arrêter le partage'*/}
                {/*    onClick = { () => handleHangup() }>*/}
                {/*    <FontAwesomeIcon icon={solid('rectangle-xmark')} />*/}
                {/*</MicroButton>*/}
                <MicroButton
                    red
                    title = 'Raccrocher'
                    onClick = { () => handleHangup() }>
                    <FontAwesomeIcon icon={solid('phone')} />
                </MicroButton>
            </MicroButtonContainer>
        </div>
    );

    const renderSpinner = () => (
        <></>
    );

    const hangupButton = () => {
        let text: string = "Leave"
        if (user?.isOrganizer(room) || (room.isStaff() && user?.isStaff() && getUsers(room.id).filter(user => user.isStaff()).length === 1)) {
             text = "End the meeting"
        }
        return isInit ? <Button hangup onClick={handleHangup}>{text}</Button> : <Button disabled>{text}</Button>
    }

    return (
        <Wrapper $pip={pip}>
            {!pip && <SidePanel>
                <Header>{room.name}</Header>
                <Content>
                    <ButtonContainer>
                        {roomVoter.can(room, "toggle-door") && room.available && <Button onClick={handleCloseDoor}>Close the door</Button>}
                        {roomVoter.can(room, "toggle-door") && !room.available && <Button onClick={handleOpenDoor}>Open the door</Button>}
                        {hangupButton()}
                    </ButtonContainer>
                    {/*<h2>Raw Data</h2>*/}
                    {/*<pre>*/}
                    {/*    {JSON.stringify(room, null, 4)}*/}
                    {/*</pre>*/}
                    <Lists>
                        <UserList users={getUsers(room.id)} room={room} name="Attendees" theme={"in-room"} withActions={true}/>
                        {room.hasWaitingList && <UserList users={getWaitingUsers(room.id)} room={room} name={"Waiting "+(room.isStaff() ? "line" : "area")} theme={"in-room"} withActions={room.isStaff()}/>}
                    </Lists>
                </Content>
            </SidePanel>}
            <MeetingWrapper>
                {!pip && <div style = {{
                    fontFamily: 'sans-serif',
                    textAlign: 'center'
                }}>
                    <MeetingHeader>
                        <div>{room.name} - {callDuration}</div>
                        <div>{isModerator && <div>(has Moderator)</div>}</div>
                        <div>
                            {isInit && <FontAwesomeIcon icon={solid("arrow-up-right-from-square")} onClick={togglePIP} />}
                        </div>
                    </MeetingHeader>
                </div>}
                {pip && <div>
                    <PipRow>
                        <PipSubject>{room.name}</PipSubject>
                        <FontAwesomeIcon icon={solid("up-right-and-down-left-from-center")} onClick={togglePIP} />
                    </PipRow>
                    <PipRow>
                        <div>{callDuration}</div>
                        {renderButtons()}
                    </PipRow>
                </div>}
                <VideoWrapper className={isInit ? 'initied' : 'initiating'}>
                     <Loader className={"conf-loader"}>
                         Joining...
                     </Loader>
                    {currentJwt && <JaaSMeeting
                         appId = { "vpaas-magic-cookie-f3cd9ebfefd14e08acacec6f6199fed6" }
                         roomName = { ENV_NAME + "-" + room.slug }
                         spinner = { renderSpinner }
                         onReadyToClose = { handleReadyToClose }
                         onApiReady = { externalApi => handleApiReady(externalApi) }
                         getIFrameRef = { handleJaaSIFrameRef }
                         configOverwrite = {defaultConfig}
                         jwt={currentJwt}
                     />}
                </VideoWrapper>
            </MeetingWrapper>
        </Wrapper>
    );
};

const Loader = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #202C3A;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Wrapper = styled.div<{$pip: boolean}>`
    max-width: ${props => props.$pip ? '100%' : 'min(1200px, calc(100% - 330px))'};
    margin-right: ${props => props.$pip ? '0px' : '330px'};
    width: ${props => props.$pip ? '350px' : 'calc(100vw - 330px)'};
`

const VideoWrapper = styled.div`
    position: relative;
    aspect-ratio: 16/9;
    overflow: hidden;
    
    &.initiating {
        .conf-loader {
            visibility: visible;
            opacity: 1;
        }        
    }    
    
    &.initied {
        .conf-loader {
            visibility: hidden;
            opacity: 0;
        }        
    }
`
const MeetingWrapper = styled.div`
`

const MeetingHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    color: #000;
    height: 40px;
    padding-inline: 15px;
    border-radius: 5px 5px 0 0;
`

const PipRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const PipSubject = styled.div`
    display: flex;
    font-weight: bold;
`

const MicroButtonContainer = styled.div`    
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 4px;
`

const MicroButton = styled.div<{red?: boolean}>`
    display: flex;    
    align-items: center;
    justify-content: center;
    background-color: ${props => props.red ? "var(--error-color)" : "transparent"};
    width: 32px;
    height: 26px;
    cursor: pointer;
    user-select: none;
    border-radius: 3px;
    
    [data-icon="phone"] {
        transform: rotate(135deg);
    }
`
const SidePanel = styled.div`
    background-color: #202C3A;
    color: #fff;
    position: fixed;
    transition-duration: 300ms;
    right: 0;
    top: 70px;
    bottom: 0;
    min-height: 600px;
    width: 300px;
    //border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px;
`

const Content = styled.div`
    padding: 10px 15px;
    z-index: 1;
    height: calc(100vh - 45px - 70px - 20px);
    overflow: auto;
    
    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    scrollbar-width: auto;
    scrollbar-color: #2C4853 #183139;

    /* Chrome, Edge, and Safari */

    &::-webkit-scrollbar {
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background: #183139;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2C4853;
        border-radius: 10px;
        border: 3px solid #183139;
    }
`

const Header = styled.div`
    background-color: #2d4854;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 0 15px;
    margin: 0;
    height: 45px;
    line-height: 45px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
    font-weight: 500;
`

const Lists = styled.div`
    padding: 0 15px;
`

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    
    > button {
        width: 50%;
        min-width: auto;
        margin-inline: 0;
    } 
`

export default Meeting;
