import React, {useEffect} from 'react';
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {useAppStore} from "../../providers/RootStoreProvider";

const Logout = observer(() => {
    const navigate = useNavigate();
    const {authenticated, setAuthenticated} = useAppStore()

    useEffect(() => {
        setAuthenticated(false)
        navigate("/", { replace: true });
    }, [authenticated])

    return (
        <div>
            Logout...
        </div>
    );
});

export default Logout;
