import styled from 'styled-components'
import {ReactNode, useState} from "react";
import {observer} from "mobx-react-lite";
import { GoogleLogin, CredentialResponse } from '@react-oauth/google';
import * as jose from "jose"
import {JWTPayload} from "jose";
import {useNavigate} from "react-router-dom";
import {useAppStore} from "../../providers/RootStoreProvider";
import {API_ROOT as apiPath, GOOGLE_HOSTED_DOMAIN} from "../../config/url";

type Props = {
    children?: ReactNode
}

const ConnectButton = observer(({children}: Props) => {
    const [userData, setUserData] = useState<JWTPayload>()
    const navigate = useNavigate();
    const {authenticated, setAuthenticated} = useAppStore()

    return !userData?.picture ? <GoogleLogin
            hosted_domain={GOOGLE_HOSTED_DOMAIN}
            onSuccess={(credentialResponse: CredentialResponse) => {
                const data = jose.decodeJwt(credentialResponse.credential as string)
                fetch(`${apiPath}/access-token`, {
                    method: "POST", // or 'PUT'
                    credentials: "include",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(credentialResponse)
                })
                    .then((response) => response.json())
                    .then((data) => {
                        localStorage.setItem('token', data.token)
                        localStorage.setItem('refresh_token', data.refresh_token)
                        setAuthenticated(true)
                        navigate("/", { replace: true });
                        // fetch(`${apiPath}/refresh-token`, {
                        //     method: "POST", // or 'PUT'
                        //     headers: {
                        //         "Content-Type": "application/json",
                        //     },
                        //     body: JSON.stringify({refresh_token: data.refresh_token})
                        // })
                        // .then((response) => response.json())
                        // .then((data) => {
                        //     localStorage.setItem('token', data.token)
                        //     localStorage.setItem('refresh_token', data.refresh_token)
                        //     setAuthenticated(true)
                        //     navigate("/", { replace: true });
                        // })
                        // .catch((error) => {
                        //     console.error("Error:", error);
                        // });
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
                setUserData(data)
            }}
            onError={() => {
                // console.log('Login Failed');
            }}
        /> : <>
        {/*{userData?.picture ? <img src={userData.picture} /> : ''}*/}
        Bonjour {userData?.name}
    </>

})


const MyCustomButton = styled.div`
    background-color: dodgerblue;
    color: white;
`

export default ConnectButton
