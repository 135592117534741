import UserList from "../../User/UserList";
import {useAppStore} from "../../../providers/RootStoreProvider";
import {Button} from "../../global/common";
import Room from "../../../models/Room";
import {ReactNode, useState} from "react";
import styled from "styled-components";
import {JsonEvent} from "../../../interfaces/event";
import moment from "moment";
import useVoter from "../../../hooks/useVoter";

type ModalProps = {
    onClose: Function,
    room: Room
}

const WorkModal = ({onClose, room}: ModalProps) => {
    const { getUsers, getWaitingUsers, isInRoom, user, moveUser, isWaitingFor, leave, inRoom, getUser } = useAppStore()
    const { roomVoter } = useVoter()
    const [displayed, setDisplayed] = useState<number>(1)

    if (!user) {
        return <></>
    }

    const handleJoin = (e: any) => {
        e.stopPropagation()
        onClose()
        moveUser(user.id, room.id)
    };

    const handleLeave = (e: any) => {
        e.stopPropagation()
        // onClose()
        leave()
    };

    const handleWait = (e: any) => {
        e.stopPropagation()
        // onClose()
        moveUser(user.id, null, room.id)
        // navigate('/room/'+room.id)
    };

    const show = (id: number) => {
        setDisplayed(id)
    }

    const roomButton = () => {
        if (user.hasRole('ADMIN')) {
            return <Button technical onClick={handleJoin}>Technical access</Button>
        } else if (roomVoter.can(room, "leave") && isInRoom(user.id, room.id)) {
            return <Button onClick={handleLeave}>Leave the room</Button>
        } else if (inRoom()) {
            return <Button disabled>Join the room</Button>
        } else if (roomVoter.can(room, "enter")) {
            return <Button onClick={handleJoin}>Join the room</Button>
        } else if (!room.available) {
            return <Button disabled>Closed</Button>
        }
    }

    const CurrentEvent = ({event}: {event: JsonEvent}) => {
        return <EventContainer>
            <EventDate>{moment(event.startAt).format('HH:mm')} &gt; {moment(event.endAt).format('HH:mm')}</EventDate>
            <EventName>{event.name} <Badge className={room.liveEventState}>{room.liveEventState}</Badge></EventName>
            {room.currentEvent?.organizer?.id && <div>({getUser(room.currentEvent?.organizer?.id).email.split('@')[0]})</div>}
            {roomButton()}
            <UserList users={getUsers(room.id)} room={room} name="Attendees" theme={room.theme} />
        </EventContainer>
    }

    const NoCurrentEvent = () => {
        return <EventContainer>
            <div>No Event</div>
            {roomButton()}
            <UserList users={getUsers(room.id)} room={room} name="Attendees" theme={room.theme} />
        </EventContainer>
    }

    const NextEvent = ({event}: {event: JsonEvent}) => {
        let button: ReactNode
        if (inRoom()) {
            button = <Button disabled>Join the waiting area</Button>
        } else if (roomVoter.can(room, "wait")) {
            button = <Button onClick={handleWait}>Join the waiting area</Button>
        } else {
            button = <Button onClick={handleLeave}>Leave the waiting area</Button>
        }

        return <EventContainer>
            <EventDate>{moment(event.startAt).format('HH:mm')} &gt; {moment(event.endAt).format('HH:mm')}</EventDate>
            <EventName>{event.name}</EventName>
            {room.nextEvent?.organizer?.id && <div>({getUser(room.nextEvent?.organizer?.id).email.split('@')[0]})</div>}
            {button}
            <UserList users={getWaitingUsers(room.id)} room={room} name="Waiting area" theme={room.theme} />
        </EventContainer>
    }

    const NoNextEvent = () => {
        return <EventContainer>
            <div>No Event</div>
        </EventContainer>
    }

    return <>
        <TabMenuContainer>
            <TabMenu onClick={() => show(1)} className={displayed === 1 ? "active" : ""}>LIVE</TabMenu>
            <TabMenu onClick={() => show(2)} className={displayed === 2 ? "active" : ""}>NEXT EVENT</TabMenu>
        </TabMenuContainer>

        <div {...{hidden: displayed !== 1}}>
            {room.currentEvent ? <CurrentEvent event={room.currentEvent}/>  : <NoCurrentEvent />}
        </div>

        <div {...{hidden: displayed !== 2}}>
            {room.nextEvent ? <NextEvent event={room.nextEvent}/>  : <NoNextEvent />}
        </div>
    </>
}

const TabMenuContainer = styled.div`
    display: flex;
    align-content: center;
    justify-content: space-evenly;
    align-items: center;
    margin: -10px -15px 0 -15px;
`

const TabMenu = styled.div`
    height: 45px;
    background-color: #fff;
    width: 50%;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    user-select: none;
    font-size: 14px;

    &.active {
        font-weight: 600;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            height: 4px;
            background-color: #2386B6;
            width: 100%;
            bottom: -2px;
        }
    }
`

const EventContainer = styled.div`
    padding: 25px 0 10px 0;
`

const EventDate = styled.div`
    font-style: italic;
    padding: 8px 0;
    font-size: 15px;
`

const EventName = styled.div`
    font-weight: 600;
    padding: 8px 0;
    font-size: 15px;
`

const Badge = styled.span`
    text-transform: uppercase;
    color: #fff;
    background-color: #EB755E;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    
    &.over {
        background-color: #565B5B;
        color: #fff;
    }
    
    &.late {
        background-color: #EB755E;
        color: #fff;
    }
    
    &.live {
        background-color: #4DDE75;
        color: #000;
    }
`

export default WorkModal
