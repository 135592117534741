import AppStore from "./AppStore";
// import AccountStore from "./AccountStore";

class RootStore {
    appStore: AppStore
    // accountStore: AccountStore

    constructor() {
        this.appStore = new AppStore(this)
        // this.accountStore = new AccountStore(this)
    }
}

export default RootStore;
