import {createPortal} from "react-dom";
import {ReactNode, RefObject, useEffect, useState} from "react";
import styled from "styled-components";
import useClickOutside from "../../hooks/useClickOutside";
import usePressEscapeKey from "../../hooks/usePressEscapeKey";


type ModalProps = {
    show: boolean,
    pip: boolean,
    onClose: Function,
    children?: ReactNode
}

type ShowModalProps = {
    $show: boolean
}

const Modal = ({show, onClose, pip, children}: ModalProps) => {
    const [init, setInit] = useState<boolean>(false)

    const close = () => {
        setInit(false)
        onClose()
    }

    const ref = useClickOutside(() => {
        close()
    });

    usePressEscapeKey(() => {
        close()
    });

    useEffect(() => {
        setTimeout(() => {
            setInit(show)
        }, 1)
    }, [show])

    // const handleCloseClick = (e: MouseEvent) => {
    //     e.preventDefault();
    //     onClose();
    // };

    const modalContent = <StyledModalOverlay $pip={pip}>
        <StyledModal ref={ref} $show={init}>
            <StyledModalBody>{children}</StyledModalBody>
        </StyledModal>
    </StyledModalOverlay>

    return createPortal(
        modalContent,
        document.getElementById("modal-root") as HTMLElement
    );
}

const StyledModalBody = styled.div`
    //padding-top: 10px;
    display: flex;
    justify-content: center;
    z-index: 1;
`;

const StyledModal = styled.div<ShowModalProps>`
    //background-color: #fff;
    //position: absolute;
    transition-duration: 300ms;
    //right: 0;
    //top: 70px;
    //bottom: 0;
    //min-height: 600px;
    //width: 50%;
    //border-radius: 15px;
    //padding: 15px;
    //box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px;
`;

const StyledModalOverlay = styled.div<{$pip: boolean}>`
    position: fixed;
    top: ${props => props.$pip ? 'auto' : '0'};
    left: ${props => props.$pip ? '25px' : '0'};
    right: ${props => props.$pip ? 'auto' : '0'};
    bottom: ${props => props.$pip ? '25px' : '0'};
    width: ${props => props.$pip ? 'auto' : '100%'};;
    height: ${props => props.$pip ? 'auto' : '100%'};
    padding: 15px;
    border-radius: ${props => props.$pip ? '8px' : '0'};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(24, 49, 57, 0.8);
    color: #fff;
    z-index: 1000;
`;

export default Modal
