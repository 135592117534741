import {createPortal} from "react-dom";
import {ReactNode, MouseEvent, useEffect, useState} from "react";
import styled from "styled-components";
import useClickOutside from "../../hooks/useClickOutside";
import usePressEscapeKey from "../../hooks/usePressEscapeKey";
import ConnectingModal from "../Room/Modal/ConnectingModal";
import DefaultModal from "../Room/Modal/DefaultModal";
import Room from "../../models/Room";
import WorkModal from "../Room/Modal/WorkModal";
import ConstructionModal from "../Room/Modal/ConstructionModal";
import StaffModal from "../Room/Modal/StaffModal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "../../helpers/fontawesome";
import OpenSpaceModal from "../Room/Modal/OpenSpaceModal";


type ModalProps = {
    show: boolean,
    onClose: Function,
    room: Room
    children?: ReactNode
}

type ShowModalProps = {
    $show: boolean
}

const SideModal = ({show, onClose, room, children}: ModalProps) => {
    const [init, setInit] = useState<boolean>(false)

    const close = () => {
        setInit(false)
        onClose()
    }

    const ref = useClickOutside(() => {
        close()
    });

    usePressEscapeKey(() => {
        close()
    });

    useEffect(() => {
        setTimeout(() => {
            setInit(show)
        }, 1)
    }, [show])

    const handleCloseClick = (e: MouseEvent<HTMLElement>) => {
        e.preventDefault();
        onClose();
    };

    const renderModal = () => {
        switch (room.type) {
            case "sas": return <ConnectingModal onClose={onClose} room={room} />
            case "room":
            case "sport":
            case "cinema":
            case "conference": return <WorkModal onClose={onClose} room={room} />
            case "staff": return <StaffModal onClose={onClose} room={room} />
            case "open-space": return <OpenSpaceModal onClose={onClose} room={room} />
            default: return <ConstructionModal room={room} />
            // default: return <DefaultModal onClose={onClose} room={room} />
        }

    }

    const headerClassname = [
        room.theme,
        "header-" + room.type
    ]

    const modalContent = <StyledModalOverlay>
        <StyledModal ref={ref} $show={init} className={room.theme}>
            <StyledModalHeader className={headerClassname.join(" ")}>
                {room.name}
                <CloseButton onClick={handleCloseClick}>
                    <FontAwesomeIcon icon={solid("xmark")} />
                </CloseButton>
            </StyledModalHeader>
            <StyledModalBody className={room.theme}>
                {/*<h2>Raw Data</h2>*/}
                {/*    <pre>*/}
                {/*        {JSON.stringify(room, null, 4)}*/}
                {/*    </pre>*/}
                {renderModal()}
            </StyledModalBody>
        </StyledModal>
    </StyledModalOverlay>

    return createPortal(
        modalContent,
        document.getElementById("modal-root") as HTMLElement
    );
}

const StyledModalBody = styled.div`
    padding: 10px 15px;
    z-index: 1;
    height: calc(100vh - 45px - 70px - 20px);
    overflow: auto;

    /* ===== Scrollbar CSS ===== */
    /* Firefox */
    scrollbar-width: auto;
    scrollbar-color: #c3d2d0 #ebeced;

    /* Chrome, Edge, and Safari */

    &::-webkit-scrollbar {
        width: 16px;
    }

    &::-webkit-scrollbar-track {
        background: #ebeced;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #c3d2d0;
        border-radius: 10px;
        border: 3px solid #ebeced;
    }

    &.dark {
        /* ===== Scrollbar CSS ===== */
        /* Firefox */
        scrollbar-width: auto;
        scrollbar-color: #2C4853 #183139;

        /* Chrome, Edge, and Safari */

        &::-webkit-scrollbar {
            width: 16px;
        }

        &::-webkit-scrollbar-track {
            background: #183139;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #2C4853;
            border-radius: 10px;
            border: 3px solid #183139;
        }
    }
`;

const StyledModalHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    padding: 0 15px;
    margin: 0;
    height: 45px;
    line-height: 45px;
    text-transform: uppercase;
    font-family: 'Roboto Slab', serif;
    font-weight: 500;
    
    &.dark {        
        background-color: #183139;
        color: #fff;
    }
    
    &.header-room {
        background-color: var(--work-room);
        color: #000;
    }
    
    &.header-conference {
        background-color: var(--work-room);
        color: #000;
    }
    
    
    &.header-open-space {
        background-color: var(--work-door);
        color: #000;
    }
    
    &.header-sport {
        background-color: var(--sport-room);
        color: #fff;
    }
    
    &.header-cinema {
        background-color: var(--sport-room);
        color: #fff;
    }
    
    &.header-staff {
        background-color: var(--staff-room);
        color: #fff;
    }
`;

const StyledModal = styled.div<ShowModalProps>`
    background-color: #FFFFFF;
    position: absolute;
    transition-duration: 300ms;
    right: ${props => props.$show ? "0" : "-330px"};
    top: 70px;
    bottom: 0;
    min-height: 600px;
    width: 300px;
    //border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px;
        
    &.dark {        
        background-color: #2C4853;        
    }
`;

const StyledModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    //display: flex;
    //justify-content: center;
    //align-items: center;
    //background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
`;

const CloseButton = styled.div`
    cursor: pointer;
`;

export default SideModal
