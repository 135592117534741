import {useEffect, useState} from "react";
import moment from "moment";

type Props = {
    startAt: moment.Moment
}

const Timer = ({startAt}: Props) => {
    const [ callDuration, setCallDuration ] = useState<string>("00:00");

    const updateTimer = () => {
        if (startAt === null) {
            startAt = moment()
        }
        const duration = moment.duration(moment().diff(startAt))
        const roomDuration = duration.minutes().toString().padStart(2, "0") + ":" + duration.seconds().toString().padStart(2, "0")
        setCallDuration(roomDuration)
    }

    useEffect(() => {
        updateTimer()
        setInterval(updateTimer, 1000)
    }, [])

    return <>{callDuration}</>
}

export default Timer
