import UserList from "../../User/UserList";
import {useAppStore} from "../../../providers/RootStoreProvider";
import {Button} from "../../global/common";
import Room from "../../../models/Room";
import {ReactNode, useState} from "react";
import styled from "styled-components";
import {JsonEvent} from "../../../interfaces/event";
import moment from "moment";
import useVoter from "../../../hooks/useVoter";

type ModalProps = {
    onClose: Function,
    room: Room
}

const StaffModal = ({onClose, room}: ModalProps) => {
    const { getUsers, getWaitingUsers, isInRoom, user, moveUser, isWaitingFor, leave, inRoom } = useAppStore()
    const { roomVoter } = useVoter()

    if (!user) {
        return <></>
    }

    const handleJoin = (e: any) => {
        e.stopPropagation()
        onClose()
        moveUser(user.id, room.id)
    };

    const handleLeave = (e: any) => {
        e.stopPropagation()
        leave()
    };

    const handleWait = (e: any) => {
        e.stopPropagation()
        moveUser(user.id, null, room.id)
    };

    const renderButtons = () => {
        const buttons = []
        if (roomVoter.can(room, "enter-staff")) {
            buttons.push(<Button onClick={handleJoin}>Join the office</Button>)
        } else if (roomVoter.can(room, "wait-staff")) {
            buttons.push(<Button onClick={handleWait}>Join the waiting line</Button>)
        } else if (roomVoter.can(room, "stop-wait")) {
            buttons.push(<Button onClick={handleLeave}>Leave the waiting line</Button>)
        } else if (!isInRoom(user.id, room.id) && !isWaitingFor(user.id, room.id)) {
            buttons.push(<Button disabled>Closed</Button>)
        }

        return buttons
    }

    return <>
        {/*{roomVoter.can(room, "enter-staff") && <Button onClick={handleJoin}>Join the office</Button>}*/}
        {/*{roomVoter.can(room, "wait-staff") ? <Button onClick={handleWait}>Join the waiting line</Button> : <Button disabled>Closed</Button>}*/}
        {/*{roomVoter.can(room, "stop-wait") && <Button onClick={handleLeave}>Leave the waiting line</Button>}*/}
        {renderButtons()}
        <UserList users={getUsers(room.id)} room={room} name="Attendees" theme={room.theme} />
        <UserList users={getWaitingUsers(room.id)} room={room} name="Waiting line" theme={room.theme} />
    </>
}


const EventContainer = styled.div`
    padding: 25px 0 10px 0;
`

const EventDate = styled.div`
    font-style: italic;
    padding: 8px 0;
    font-size: 15px;
`

const EventName = styled.div`
    font-weight: 600;
    padding: 8px 0;
    font-size: 15px;
`

const Badge = styled.span`
    text-transform: uppercase;
    color: #fff;
    background-color: #EB755E;
    padding: 2px 5px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: bold;
    
    &.over {
        background-color: #565B5B;
        color: #fff;
    }
    
    &.late {
        background-color: #EB755E;
        color: #fff;
    }
    
    &.live {
        background-color: #4DDE75;
        color: #000;
    }
`

export default StaffModal
