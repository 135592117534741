import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import MeetDemo from "../MeetDemo";
import client from "../../helpers/api";
import {useAppStore} from "../../providers/RootStoreProvider";
import {MessagePayload} from "../../stores/AppStore";

const Room = () => {
    const params = useParams()
    const navigate = useNavigate()
    const {setMessage} = useAppStore()


    useEffect(() => {
        handleEvent()
    }, [])

    const handleEvent = async () => {
        const id = params.id

        const response = await client.get<MessagePayload>('/event/'+id)

        if (response.data.action === 'message') {
            setMessage({
                action: 'message',
                message: response.data.message as string

            })
        }
        navigate('/')
    }

    return (
        <div>
            Loading...
            {/*Event {params.id}*/}
            {/*{jwt ? <MeetDemo jwt={jwt} /> : <div>Loading</div>}*/}
        </div>
    );
};

export default Room;
