import * as jose from "jose"
import moment from "moment";
import axios, {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from "axios";
import { API_ROOT as apiPath } from "../config/url";

const jwtIsExpired = () => {
    const token: string = localStorage.getItem("token") as string
    const data = jose.decodeJwt(token)
    const expTimestamp = data.exp || 0
    // console.log(expTimestamp)
    // console.log(moment().unix())

    return expTimestamp < moment().unix()
}

const authUrls = ['/access-token', '/refresh-token', '/alternative-login']

const client = axios.create({
    baseURL: apiPath,
    withCredentials: true,
    timeout: 5000,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    }
});

client.interceptors.request.use(async (config: InternalAxiosRequestConfig) => {
    const url = config.url || ""
    const controller = new AbortController();

    if (!authUrls.includes(url) && jwtIsExpired()) {
        console.error('jwt expired... refreshing')
        // controller.abort();

        await client.post('/refresh-token', {
            refresh_token: localStorage.getItem('refresh_token')
        }).then((response) => {
            const {token, refresh_token} = response.data
            localStorage.setItem('token', token)
            localStorage.setItem('refresh_token', refresh_token)
            // return client.request(config)
        })
    }

    return {
        ...config,
        signal: controller.signal
    };
}, (error: AxiosError) => {
    console.log(error)
    return Promise.reject(error)
})

client.interceptors.response.use((response: AxiosResponse) => {
    // console.log(response)
    return response
}, (error: AxiosError) => {
    // console.log(error)
    if (error.response?.status === 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('refresh_token')
        window.location.href = "/login"
    }
    // return Promise.reject(error)
})

const getJitsiJWTSample = async () => {
    const response = await client.get('/room')
    // console.log(response.data)
}

export default client
