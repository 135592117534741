import { ReactNode, useState, MouseEvent, cloneElement, } from "react";
import useClickOutside from "../../hooks/useClickOutside";
import usePressEscapeKey from "../../hooks/usePressEscapeKey";
import styled from "styled-components";

interface MenuItemProps {
    component: ReactNode,
    children?: ReactNode
}

interface ButtonProps {
    component: ReactNode,
    onClick: any,
    className?: string,
    closed: boolean
}

interface ContentProps {
    children?: ReactNode,
    closed: boolean
}

const DropdownMenu = ({component, children}: MenuItemProps) => {
    const [closed, setClosed] = useState<boolean>(true)
    const ref = useClickOutside(() => {
        close();
    });

    usePressEscapeKey(() => {
        close();
    });

    const toggleMenu = (e: MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        setClosed(!closed)
        return e
    }

    const close = () => {
        setClosed(true)
    }

    return <DropdownContainer ref={ref} className={"dropdown-container "+(!closed ? "dropdown-container-open" : "")}>
        <Button onClick={toggleMenu} component={component} closed={closed} />
        <Dropdown children={children} closed={closed} />
    </DropdownContainer>
}

const Button = ({onClick, component, closed}: ButtonProps) => {
    const classNames = ["dropdown-button"]
    if (!closed) {
        classNames.push("dropdown-button-open")
    }

    return cloneElement(component as React.ReactElement, {onClick: onClick, className: classNames.join(' '), "data-opened": !closed})
}

const Dropdown = ({ children, closed}: ContentProps) => {
    const classNames = ["dropdown-content"]
    if (!closed) {
        classNames.push("dropdown-content-open")
    }

    return cloneElement(children as React.ReactElement, {className: classNames.join(' '), "data-opened": !closed})
}

const DropdownContainer = styled.div`
    transition-duration: 500ms;
    .dropdown-button {        
        user-select: none;
        cursor: pointer;
        transition-duration: 500ms;
    }
    
    .dropdown-content {
        transition-duration: 500ms;
        opacity: 0;
        visibility: hidden;
    }
    
    &.dropdown-container-open {
        .dropdown-content {
            transition-duration: 200ms;
            opacity: 1;            
            visibility: visible;
        }
    }
`

export default DropdownMenu
