import styled from 'styled-components'
import {ReactNode, useEffect, useRef, useState} from "react";

type Props = {
    children?: ReactNode
}

type WrapperProps = {
    scale: number
}

const RoomContainer = ({children}: Props) => {
    const ref = useRef<HTMLDivElement>(null)

    const [scale, setScale] = useState<number>(1)

    const updateSize = () => {
        if (ref.current) {
            const ratio = ref.current.clientWidth / ref.current.clientHeight
            const windowRatio = window.innerWidth / window.innerHeight
            let size

            if (windowRatio < ratio) {
                const wW = window.innerWidth - 140 - 100
                const cW = ref.current?.offsetWidth || wW
                size = Math.min(wW/cW, 1.2)
            } else {
                const wH = window.innerHeight - 70 - 100
                const cH = ref.current?.offsetHeight || wH
                size = Math.min(wH/cH, 1.2)
            }
            setScale(size)
        }
    }

    useEffect(() => {
        updateSize()
    }, [])

    window.addEventListener("resize", updateSize);

    return <Wrapper ref={ref} scale={scale}>
        {children}
    </Wrapper>
}

const Wrapper = styled.div<WrapperProps>`
    position: relative;
    height: 704px;
    width: 1104px;
    transform: scale(${props => props.scale});
    // Grid
    //background-image: linear-gradient(#d6d6d6 0.5px, transparent 0.5px, transparent calc(100% - 0.5px), #d6d6d6 calc(100% - 0.5px)), linear-gradient(90deg, #d6d6d6 0.5px, transparent 0.5px, transparent calc(100% - 0.5px), #d6d6d6 calc(100% - 0.5px));
    //background-size: 0.7246376811594203% 1.1363636363636365%;
    //border: 0.5px solid #d6d6d6;    
    background: url("/img/chemin+arbres2.svg") -66px -99px / 1220px no-repeat;
    
`

export default RoomContainer
