import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";

const NotFound = () => {
    const params = useParams()

    return (
        <div>
            404
        </div>
    );
};

export default NotFound;
