import styled from 'styled-components'
import {MouseEventHandler, useEffect, useState} from "react";
import SideModal from "../global/SideModal";
import AvatarBag from "../AvatarBag";
import {useAppStore} from "../../providers/RootStoreProvider";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular} from "../../helpers/fontawesome";
import {observer} from "mobx-react-lite";
import Door from "./Door";
import VideoCall from "./VideoCall";
import RoomModel from "../../models/Room";

type Props = {
    room: RoomModel
    onClick?: MouseEventHandler<HTMLDivElement>
}
// x, y, type

const Room = observer(({room, onClick}: Props) => {
    const { usersLocation, moveUser, isInRoom, getUsers, user } = useAppStore()

    const [displayModal, setDisplayModal] = useState<boolean>(false)
    const [modalExists, setModalExists] = useState<boolean>(false)

    const openModal = () => {
        if (!modalExists) {
            setModalExists(true)
            setDisplayModal(true)
        }
    }

    const closeModal = () => {
        setDisplayModal(false)
        setTimeout(() => {
            setModalExists(false)
        }, 300)
    }

    // useEffect(() => {
    //     window.addEventListener('beforeunload', e => {
    //         e.preventDefault()
    //         e.returnValue = `Are you sure you want to leave?`;
    //         // alert("nan")
    //     })
    // }, [])

    const users = getUsers(room.id)

    const className = [
        room.type
    ]

    if (room.template) {
        className.push(room.type+"-"+room.template)
    }

    return <>
        <Wrapper $available={room.available} x={room.x} y={room.y} onClick={openModal} className={className.join(" ")}>
            {room.name && room.displayName && <RoomName $position={room.type === 'staff' ? 'bottom' : 'top'}>{room.name}</RoomName>}
            {displayModal && <Displayed>
                <FontAwesomeIcon icon={regular("eye")} />
            </Displayed>}
            {room.doorPosition && <Door room={room} opened={room.available} />}
            <AvatarBag users={users} confidential={room.confidential} andMore={room.capacity} room={room} />
            {modalExists && <SideModal onClose={closeModal} show={displayModal} room={room} />}
        </Wrapper>


        {user && room.hasVideo && isInRoom(user.id, room.id) &&
            <VideoCall room={room} />
        }
    </>

})

// const Wrapper = styled.div<Partial<RoomModel>>`
const Wrapper = styled.div<{x: number, y: number, $available: boolean}>`
    position: absolute;
    top: ${props => props.y ? props.y * 8 + 'px' : 0 };
    left: ${props => props.x ? props.x * 8 + 'px' : 0 };
    display: inline-flex;
    flex-direction: column;
    height: 168px;
    width: 168px;
    //margin: 25px;
    background-color: ${props => props.$available ? "#38DDD1" : "#CCDBD8"};
    //background-image: url('/img/tables/T2.png');
    background-image: url('/img/tables/table-TD.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 60%;
    align-items: center;
    justify-content: center;
    //box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 6px;
    vertical-align: middle;
    
    > .avatar-bag {
        .and-more {
            position: absolute;
            bottom: 10px;
            right: 10px;
            width: 30px;
            height: 30px;
            font-size: 13px;
            color: #fff;
            border: 1px solid #fff;
            background-color: transparent;
        }
    }
    
    .door {
        background-color: #BCF2EE;
    }
    
    &.sas {
        width: 108px;
        background-color: var(--sas-room);
        background-image: none;
        border-radius: 0 0 8px 8px;
        margin-top: 12px;
        height: 156px;
        
        
        &:before {
            content: '';
            position: absolute;
            top: -12px;
            background-color: #000000;
            height: 12px;
            width: 110%;
        }
    }  
    
    &.open-space {
        width: 464px; //58
        height: 240px; //30        
        background-color: ${props => props.$available ? "#ADF4EE" : "#CCDBD8"};
        background-image: url('/img/tables/table-openspace.svg');
        background-position: center center;
        background-size: 80%;
    }
    
    &.conference {
        width: 184px;
        height: 312px; //30        
        background-image: url('/img/tables/amphi.svg');
        background-position: center bottom;
        background-size: 80%;
    }
    
    &.sport {
        width: 160px;
        height: 232px;        
        background-color: ${props => props.$available ? "#CEA4FF" : "#CCDBD8"};
        background-image: url('/img/tables/SPORT.svg');
        background-position: center center;
        background-size: 100%;
        
        .door {
            background-color: #E2D2FC;
        }
    }
    &.cinema {
        width: 160px;
        height: 160px;        
        background-color: ${props => props.$available ? "#CEA4FF" : "#CCDBD8"};
        background-image: url('/img/tables/CINEMA.svg');
        background-position: center center;
        background-size: 40%;
        
        .door {
            background-color: #E2D2FC;
        }
    }
    &.staff {        
        width: 112px;        
        background-color: ${props => props.$available ? "#6F6DF2" : "#CCDBD8"};
        background-position: center center;
        
        &.staff-1 {
            background-image: url('/img/tables/table-peda1.svg');
            background-size: 70%;
        }
        
        &.staff-2 {
            background-image: url('/img/tables/table-peda2.svg');
            background-size: 60%;
        }
        
        &.staff-3 {
            background-image: url('/img/tables/table-peda3.svg');
            background-size: 60%;
        }
        
        .door {
            background-color: #B7B6F7;
        }
    }
`

const Displayed = styled.div`
    position: absolute;            
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);  
    color: #fff;
    font-size: 36px;
`

const RoomName = styled.div<{$position: string}>`
    position: absolute;
    top: ${props => props.$position === 'top' ? '0' : 'auto'};
    bottom: ${props => props.$position === 'bottom' ? '0' : 'auto'};
    left: 0;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    margin: 8px;
`

export default Room
