import React, {ChangeEvent, FormEvent, useState} from "react"
import ConnectButton from "../global/ConnectButton";
import styled from "styled-components";
import client from "../../helpers/api";
import {useNavigate} from "react-router-dom";
import {useAppStore} from "../../providers/RootStoreProvider";
import { API_ROOT as apiPath } from "../../config/url";

const Login = () => {
    const [values, setValues] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState<Array<{code: string, message: string}>>([]);
    const navigate = useNavigate();
    const {authenticated, setAuthenticated} = useAppStore()

    if (authenticated) {
        navigate("/", { replace: true });
    }

    const displayForm = !apiPath.includes('laplateforme.io')


    const handleAtlLogin = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const data = values
        // const response = await client.post('/alternative-login', data)
        // console.log(response)

        fetch(`${apiPath}/alternative-login`, {
            method: "POST", // or 'PUT'
            credentials: "include",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.errors != undefined) {
                    setErrors(data.errors)
                } else {
                    localStorage.setItem('token', data.token)
                    localStorage.setItem('refresh_token', data.refresh_token)
                    setAuthenticated(true)
                    navigate("/", { replace: true });
                }
            })
   }

    const getHandler = (name: string) => {
        return (event: ChangeEvent<any>) => {
            setValues({ ...values, [name]: event.target.value })
        }
    }

    return <Wrapper>
        <Logo alt="La Plateforme" src={"/img/logo-xl.png"} />
        <ConnectButton />
        {displayForm && <>
            OR
            <div>
                <Form onSubmit={handleAtlLogin}>
                    <input type="text" placeholder={"email"} value={values.email} onChange={getHandler('email')} />
                    <input type="password" placeholder={"password"} value={values.password} onChange={getHandler('password')} />
                    <input type="submit" value={"Login"} />
                    {errors.length > 0 && errors.map(error => <div style={{color: "red"}}>{error.message}</div> )}
                </Form>
            </div>
            </>}
    </Wrapper>
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    margin-top: -70px;
`

const Logo = styled.img`
    height: 90px;
    margin-bottom: 50px;
`

const Form = styled.form`
    width: 250px;
    
    input {
        box-sizing: border-box;
        width: 100%;
        margin: 5px 0;
        height: 30px;
        line-height: 30px;
    }
`


export default Login
