import moment from "moment";
import {JsonNews} from "../interfaces/news";

export default class News {
    id: string
    title: string
    date: moment.Moment
    category: string
    url: string
    read: boolean

    constructor(data: JsonNews) {
        this.id = data.id
        this.title = data.title
        this.date = moment(data.date)
        this.category = data.category
        this.url = data.url
        this.read = data.read
    }

    get color () : string {
        switch (this.category) {
            case "Events":
            case "Interviews":
                return "#6f6dfa"
            default : return "#cea4ff"
        }
    }

    toJson (): JsonNews {
        return {
            id: this.id,
            title: this.title,
            date: this.date.toString(),
            category: this.category,
            url: this.url,
            read: this.read
        }
    }
}
