import Room from "./Room";
import {JsonEvent} from "../interfaces/event";

export const STUDENT  = "student"
export const STAFF  = "staff"
export const ADMIN  = "admin"

export default class User {
    id: number = 0
    username: string = ""
    is_me: boolean
    type: string
    job: string | null = null
    course: string | null = null
    promotion: string | null = null
    email: string
    avatarUrl: string
    roles: Array<string> = []
    waiting_for?: JsonEvent | null

    constructor(id: number, username: string, is_me: boolean, type: string, job: string, course: string, promotion: string, email: string, avatarUrl: string, roles: Array<string> = [], waiting_for: JsonEvent | null) {
        this.id = id
        this.username = username
        this.is_me = is_me
        this.type = type
        this.job = job
        this.course = course
        this.promotion = promotion
        this.email = email
        this.avatarUrl = avatarUrl
        this.roles = roles
        if (waiting_for) {
            this.waiting_for = waiting_for
        }
        this.roles = roles
    }

    get picture (): string {
        if (this.avatarUrl) {
            return this.avatarUrl
        }
        return "https://i.pravatar.cc/300?u=lapaalattest" + this.id
    }

    get title (): string | null {
        if (this.type === STUDENT) {
            return this.promotion
        } else {
            return this.job
        }
    }

    hasRole (role: string): boolean {
        return !!this.roles.find(target => target === "ROLE_"+role)
    }

    isOrganizer (room: Room): boolean {
        return this.id === room.liveEvent?.organizer?.id
    }

    isStaff (): boolean {
        return this.type === STAFF
    }
}
