import UserList from "../../User/UserList";
import {useAppStore} from "../../../providers/RootStoreProvider";
import Room from "../../../models/Room";

type ModalProps = {
    onClose: Function,
    room: Room
}

const ConnectingModal = ({onClose, room}: ModalProps) => {
    const { getUsers } = useAppStore()

    return <>
        <UserList users={getUsers(room.id)} room={room} theme={room.theme} />
    </>
}

export default ConnectingModal

