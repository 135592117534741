import styled from "styled-components";
import {observer} from "mobx-react-lite";
import {useAppStore} from "../../providers/RootStoreProvider";

const AppLoader = observer(() => {
    const {appLoaded} = useAppStore()

    return <Wrapper className={appLoaded ? "loaded" : "loading"}>
        <Logo alt="La Plateforme" src={"/img/logo-xl.png"} />
        <div>Loading virtual school...</div>
    </Wrapper>
})

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #F5F6F7;
    z-index: 1000;
    transition: opacity 500ms, visibility 0s linear 500ms;
    
    &.loaded {
        opacity: 0;
        visibility: hidden;
    }
`

const Logo = styled.img`
    height: 90px;
    margin-bottom: 50px;
`

export default AppLoader
