import moment from "moment";

export default class UserLocation {
    user_id: number
    room_id: number | null = null
    waiting_id: number | null = null
    here_since: moment.Moment | null = null

    constructor(user_id: number, room_id: number | null, waiting_id: number | null, here_since: string | null) {
        this.user_id = user_id
        this.room_id = room_id
        this.waiting_id = waiting_id
        if (here_since) {
            this.here_since = moment(here_since)
        }
    }
}
