import styled from 'styled-components'
import User from "../../models/User";
import UserItemList from "./UserItemList";
import Room from "../../models/Room";

type Props = {
    users: Array<User>
    room: Room
    emptyMessage?: string
    name?: string
    theme: string
    withActions? : boolean
}

const UserList = ({users, room, name, emptyMessage, theme, withActions}: Props) => {
    if (!emptyMessage) {
        emptyMessage = "No attendees for the moment"
    }

    return <Wrapper>
        {name && <Name>{name}</Name>}
        {users.length > 0 ? users.map(user => <UserItemList key={user.id} user={user} room={room} theme={theme} withActions={withActions} />) : <Empty>{emptyMessage}</Empty>}
    </Wrapper>
}

const Wrapper = styled.div`
`

const Empty = styled.div`
    font-style: italic;
`

const Name = styled.h2`
    font-size: 14px;
    font-weight: 600;
`

export default UserList
