import {createPortal} from "react-dom";
import {ReactNode, RefObject, useEffect, useState} from "react";
import styled from "styled-components";

type ModalProps = {
    children?: ReactNode
}

const MessagingModal = ({children}: ModalProps) => {
    const modalContent = <StyledModalOverlay>
        <StyledModal>
            <StyledModalBody>{children}</StyledModalBody>
        </StyledModal>
    </StyledModalOverlay>

    return createPortal(
        modalContent,
        document.getElementById("modal-root") as HTMLElement
    );
}

const StyledModalBody = styled.div`
    //padding-top: 10px;
    width: 350px;
    display: flex;
    align-content: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #2386B6;
    z-index: 1;
    border-radius: 8px;
    padding: 25px;
    
    button {
        border: 1px solid #fff;
        margin-bottom: 0;
        margin-top: 25px;
    }
`;

const StyledModal = styled.div`
    transition-duration: 300ms;
`;

const StyledModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(24, 49, 57, 0.8);
    color: #fff;
    z-index: 1000;
`;

export default MessagingModal
