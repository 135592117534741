import React, {useEffect, useRef, useState} from 'react';
import Room from "../Room/Room";
import {observer} from "mobx-react-lite";
import {useAppStore} from "../../providers/RootStoreProvider";
import styled from "styled-components";
import moment from "moment";
import RoomContainer from "../Room/RoomContainer";
import client from "../../helpers/api";
import {API_ROOT, MERCURE_HUB as mercurePath} from "../../config/url";
import { EventSourcePolyfill } from "event-source-polyfill/src/eventsource.min"
import {MessagePayload} from "../../stores/AppStore";
import MessagingModal from "../global/MessagingModal";
import {Button} from "../global/common";
import Invitation from "../Message/Invitation";
import AppLoader from "../Layout/AppLoader";


const Home = observer(() => {
    const {updateLocation, updateRooms, rooms, user, setMessage, clearMessage, message, setRooms, initLocations} = useAppStore()
    const [mercureToken, setMercureToken] = useState<string>()
    const [focusCount, setFocusCount] = useState<number>(0)

    const globalEventSource = useRef<EventSource | null>(null);

    let beaconSent: boolean = false

    const handleFocus = () => {
        setRooms()
        initLocations()
        setFocusCount(focusCount + 1)
    }

    // const handleBlur = () => {
    //     console.log("blur "+moment().format('HH:mm:ss') )
    // }

    useEffect(() => {
        if (user && !beaconSent) {
            beaconSent = true
            window.addEventListener('beforeunload', (e) => {
                // client.get('/close-app')
                navigator.sendBeacon(API_ROOT+'/close-app/'+user.id)
            });

            // window.removeEventListener('blur', handleBlur)
            // window.addEventListener('blur', handleBlur)

            window.removeEventListener('focus', handleFocus)
            window.addEventListener('focus', handleFocus)
        }

    }, [beaconSent, user])

    const getCookie = async () => {
            // try {
                const response = await client.get('/sse-token')
                setMercureToken(response.data.mercureToken)
                // console.log(response.data)
            // } catch (error) {
            //     console.log(error)
            // }

        }

    const init = async () => {
        await getCookie()
    }

    const handlePersonalMessage = (data: MessagePayload) => {
        setMessage(data)
    }

    function subscribeTopics(topics: Array<{topic: string, callback: (data: any) => void}>): EventSource {
        // console.log('------------- Init EventSource --------------')
        // console.log(globalEventSource.current)
        if (globalEventSource.current != null) {
            globalEventSource.current.close()
        }
        const hubUrl = `${mercurePath}/.well-known/mercure`
        const u = new URL(hubUrl);
        topics.map(item => {
            u.searchParams.append('topic', 'https://laplateforme.io/'+item.topic);
        })
        const eventSource = new EventSourcePolyfill(u, {
            // withCredentials: true
            headers: {
                Authorization: "Bearer "+mercureToken
            },
            heartbeatTimeout: 120 * 60000 //2h
            // heartbeatTimeout: 20000 //2h
        });

        //@ts-ignore
        eventSource.onmessage = (e) => {
            const data = JSON.parse(e.data)
            const topic = topics.find(item => 'https://laplateforme.io/'+item.topic === data.topic)
            if (topic) {
                topic.callback(data.payload)
            }
            if (data.sign !== undefined) {
                client.get('/debug/subscribe/'+data.sign)
            }
        }

        //@ts-ignore
        eventSource.addEventListener("error", (e) => {
            // console.log(e)
            // console.log(e.message)
            // console.log("close "+moment().format('HH:mm:ss') )
        })
        //@ts-ignore
        eventSource.addEventListener("open", (e) => {
            // console.log("open "+moment().format('HH:mm:ss') )
            if (user) {
                setRooms()
                initLocations()
            }
        })

        // setInterval(() => {
        //     console.log(eventSource.readyState)
        // }, 5000)

        return eventSource
    }

    useEffect(() => {
        if (user && mercureToken) {
            const topics = [
                {
                    topic: 'users-location',
                    callback: updateLocation
                },
                {
                    topic: 'rooms',
                    callback: updateRooms
                },
                {
                    topic: 'user/'+user.id,
                    callback: handlePersonalMessage
                },
            ]
            globalEventSource.current = subscribeTopics(topics)
        }
    }, [user, mercureToken, focusCount])

    useEffect(() => {
        init()
    }, [])

    const renderMessage = () => {
        if (message) {
            switch (message.action) {
                case "kick":
                case "cancel-accept":
                case "refuse":
                case "message":
                    return <>
                        <div>{message.message}</div>
                        <Button onClick={clearMessage}>OK</Button>
                    </>
                case "accept":
                    return <Invitation message={message} />
            }
        }
    }

    return (
        <Wrapper>
            <AppLoader />
            {/*<Link to={"/room/1"}>Room 1</Link>*/}
            {/*<Link to={"/room/2"}>Room 2</Link>*/}
            {message && <MessagingModal>{renderMessage()}</MessagingModal>}

            <RoomContainer>
                {rooms.map(room => <Room key={room.id} room={room} />)}
            </RoomContainer>
        </Wrapper>
    );
});

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 70px);
`

export default Home;
