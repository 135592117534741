import React, { createContext, ReactNode, useContext } from "react";
import RootStore from "../stores/RootStore";


let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);
StoreContext.displayName = "StoreContext";

export function useRootStore() {
    const context = useContext(StoreContext);
    if (context === undefined) {
        throw new Error("useRootStore must be used within RootStoreProvider");
    }

    return context;
}

export function useAppStore() {
    const { appStore } = useRootStore();
    return appStore;
}

export function RootStoreProvider({ children }: { children: ReactNode }) {
    store = store ?? new RootStore()

    return <StoreContext.Provider value={store}>
        {children}
    </StoreContext.Provider>;
}
