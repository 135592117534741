import UserList from "../../User/UserList";
import {useAppStore} from "../../../providers/RootStoreProvider";
import {Button} from "../../global/common";
import Room from "../../../models/Room";
import useVoter from "../../../hooks/useVoter";

type ModalProps = {
    onClose: Function,
    room: Room
}

const OpenSpaceModal = ({onClose, room}: ModalProps) => {
    const { getUsers, isInRoom, user, moveUser, leave, inRoom } = useAppStore()
    const { roomVoter } = useVoter()

    if (!user) {
        return <></>
    }

    const handleJoin = (e: any) => {
        e.stopPropagation()
        onClose()
        moveUser(user.id, room.id)
    };

    const handleLeave = (e: any) => {
        e.stopPropagation()
        // onClose()
        leave()
    };

    const roomButton = () => {
        if (user.hasRole('ADMIN')) {
            return <Button technical onClick={handleJoin}>Technical access</Button>
        }
        else if (roomVoter.can(room, "leave") && isInRoom(user.id, room.id)) {
            return <Button onClick={handleLeave}>Leave the room</Button>
        } else if (inRoom()) {
            return <Button disabled>Join the room</Button>
        } else if (roomVoter.can(room, "enter")) {
            return <Button onClick={handleJoin}>Join the room</Button>
        }
    }

    return <>
        {roomButton()}
        <UserList users={getUsers(room.id)} room={room} name="Attendees" theme={room.theme} />
    </>
}

export default OpenSpaceModal
