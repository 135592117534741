import styled from "styled-components";
import DropdownMenu from "./DropdownMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular} from "../../helpers/fontawesome";
import {useAppStore} from "../../providers/RootStoreProvider";
import {observer} from "mobx-react-lite";
import moment from "moment";

const NotificationMenu = observer(() => {
    const {news: notifications} = useAppStore()
    const notificationCount = notifications.length
    const unreadNotifications = notifications.filter(notification => !notification.read)
    const unreadNotificationCount = unreadNotifications ? unreadNotifications.length : 0


    const button = <NotificationButton>
        <FontAwesomeIcon icon={regular("bell")} size={'xl'}/>
        {unreadNotificationCount > 0 && <UnreadNotificationChip $big={unreadNotificationCount >= 10}>{unreadNotificationCount}</UnreadNotificationChip>}
    </NotificationButton>

    return <DropdownMenu component={button}>
        <NotificationContainer>
            <NotificationHeader>
                <NotificationCount>Notifications ({notificationCount})</NotificationCount>
                <UnreadNotificationCount>{unreadNotificationCount} unread
                    notification{unreadNotificationCount > 1 ? 's' : ''}</UnreadNotificationCount>
            </NotificationHeader>
            <NotificationList>
                {notifications.map((notification, key) => (
                    <NotificationItem {...notification} color={notification.color} key={key}/>
                ))}
            </NotificationList>
        </NotificationContainer>
    </DropdownMenu>
})

interface NotificationItemProps {
    id: string,
    category: string,
    url: string,
    title: string,
    date: moment.Moment,
    read: boolean,
    color?: string,
    className?: string
}

interface NotificationTypeProps {
    color?: string,
    category: string,
    read: boolean,
    className?: string
}

interface NotificationTitleProps {
    read: boolean,
    title: string,
    className?: string
}

const UnreadNotificationChip = styled.div<{$big: boolean}>`
    background-color: #fa6d6d;
    color: #fff;
    border-radius: 9px;
    text-align: center;
    font-size: 14px;
    font-weight: 700;
    position: absolute;
    min-width: 18px;
    height: 18px;
    top: 23px;
    right: 63px;
    padding: ${props => props.$big ? "0 4px" : "0"};
`

const NotificationButton = styled.div`
    font-size: 24px;
    cursor: pointer;
`

const NotificationContainer = styled.div`
    position: absolute;
    top: 71px;
    background-color: #fff;
    width: 400px;
    right: 10px;
    border-radius: 0 0 15px 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
`

const NotificationHeader = styled.div`
    height: 60px;
    padding: 15px 15px 0 15px;
    color: #1d2a38;
`

const NotificationCount = styled.div`
    font-weight: 700;
    font-size: 22px;
`

const UnreadNotificationCount = styled.div`
    font-size: 18px;
`

const NotificationList = styled.div`
    overflow: auto;
    max-height: calc(100vh - 70px - 75px - 25px);
    border-radius: 0 0 15px 15px;
`

const NotificationItemRaw = ({id, category, title, url, date, read, color, className}: NotificationItemProps) => {
    const {readNews} = useAppStore()
    return <a href={url} className={className} target={"_blank"} onClick={() => {
        readNews(id)
    }
    }>
        <NotificationTopContainer>
            <NotificationType color={color} category={category} read={read}/>
            <NotificationDate>{date.format("DD/MM/YYYY")}</NotificationDate>
        </NotificationTopContainer>
        <NotificationTitle read={read} title={title}/>
    </a>
}

const NotificationItem = styled(NotificationItemRaw)`
    padding: 15px;
    display: block;
    background-color: ${props => props.read ? "#f6f7f8" : "#fff"};
    text-decoration: none;
    border-bottom: 1px solid ${props => props.read ? "#fff" : "#f6f7f8"};

    &:last-of-type {
        border-radius: 0 0 15px 15px;
    }
`

const NotificationTopContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
`

const NotificationTitleRaw = ({read, title, className}: NotificationTitleProps) => {
    return <div className={className}>{title}</div>
}

const NotificationTitle = styled(NotificationTitleRaw)`
    color: #1a2a32;
    font-size: 15px;
    font-weight: ${props => props.read ? 400 : 700};
`

const NotificationDate = styled.div`
    font-size: 15px;
    float: right;
    color: #1a2a32;
`

const NotificationTypeRaw = ({color, category, read, className}: NotificationTypeProps) => {
    return <div className={className} style={{'backgroundColor': color}}>{category}</div>
}

const NotificationType = styled(NotificationTypeRaw)`
    width: 80px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    border-radius: 8px;
    padding: 1px 0;
    font-size: 14px;
    opacity: ${props => props.read ? 0.5 : 1};
`

export default NotificationMenu
