import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "../../helpers/fontawesome";
import DropdownMenu from "./DropdownMenu";

const SupportButton = () => {

    const button = <Button>
        <FontAwesomeIcon icon={solid("exclamation")} className={"closed"} />
        <FontAwesomeIcon icon={solid("close")} className={"opened"} />
    </Button>

    return <Container>
        <DropdownMenu component={button}>
            <Content>
                <div>
                    <strong>HELP !</strong>
                    <div>For any technical help, please contact <HelpLink href="mailto:si@laplateforme.io">si@laplateforme.io</HelpLink></div>
                </div>
            </Content>
        </DropdownMenu>
    </Container>
}

const Container = styled.div`
    position: fixed;
    bottom: 25px;
    right: 25px;
    z-index: 10000;
`

const Button = styled.div`
    display: flex;
    position: relative;
    background-color: #4080ae;
    color: #fff;
    border-radius: 70px;
    height: 70px;
    width: 70px;
    align-items: center;
    justify-content: center;
    font-size: 36px;
    z-index: 100;
    
    &:before {
        content: "";
        position: absolute;
        width: 48px;
        height: 48px;
        border-radius: 48px;
        border: 3px solid #fff;
    }
    
    &[data-opened=true] {
        .closed {
            display: none;
        }
        .opened {
            display: block;
        }
    }
    
    &[data-opened=false] {
        .closed {
            display: block;
        }
        .opened {
            display: none;
        }
    }
`

const Content = styled.div`
    //background-color: red;
    overflow: hidden;
    width: 350px;
    height: 70px;
    position: absolute;
    top: 0;
    right: 35px;
    font-size: 15px;
    
    > div {
        display: flex;
        background-color: #fff;
        height: 70px;
        width: 275px;
        border-radius: 35px 0 0 35px;    
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
        padding: 0 50px 0 25px;
        z-index: 10;
        transform: translateX(100%);
        transition-duration: 200ms;
    }
        
    &[data-opened=true] {
        > div {
            transform: translateX(0%);
        }
    }
`

const HelpLink = styled.a`
    text-decoration: underline;
    color: var(--sas-room);
    font-weight: bold;
`

export default SupportButton
