import styled from "styled-components";
import DropdownMenu from "./DropdownMenu";

const googleIcons: Array<GoogleIconProps> = [
    {
        icon: "calendar",
        label: "Calendar",
        url: "https://calendar.google.com"
    },
    {
        icon: "gmail",
        label: "Gmail",
        url: "https://mail.google.com"
    },
    {
        icon: "drive",
        label: "Drive",
        url: "https://drive.google.com"
    },
    {
        icon: "chat",
        label: "Chat",
        url: "https://chat.google.com"
    },
]

const AppMenu = () => {
    const button = <MenuButton>
        <span className="material-symbols-outlined">apps</span>
    </MenuButton>

    return <DropdownMenu component={button}>
        <AppBag>
            {googleIcons.map((icon, key) => (
                <GoogleIcon {...icon} key={key}/>
            ))}
        </AppBag>
    </DropdownMenu>
}

interface MenuButtonProps {
    closed?: boolean
}

interface GoogleIconProps {
    icon: string,
    label: string,
    url: string,
    className?: string
}

const MenuButton = styled.div<MenuButtonProps>`
    width: 40px;
    height: 40px;
    background-color: #dee1e4;
    display: flex;
    align-items: center;
    color: #5c6067;
    border-radius: 20px;
    justify-content: center;
    font-size: 24px;
    transition-duration: 200ms;
    
    &[data-opened=true] {
        background-color: #a6abaf;
    }
`

const AppBag = styled.div`
    position: absolute;
    display: flex;
    background-color: #fff;
    height: 70px;
    padding: 0 15px;
    align-items: center;
    gap: 15px;
    justify-content: space-between;
    top: 72px;
    right: 0;
`

const GoogleIconRaw = ({icon, label, url, className}: GoogleIconProps) => {
    return <a className={className} href={url} target={"_blank"}>
        <img src={"/img/google/logo_"+icon+"_2020q4_color_1x_web_96dp.png"} alt={label}/>
    </a>
}

const GoogleIcon = styled(GoogleIconRaw)`
    display: block;
    height: 48px;
    
    img {
        height: inherit;
        transition-duration: 200ms;
        
        &:hover {
            //filter: grayscale(100);
        }
    }
`

export default AppMenu
