import styled from 'styled-components'
import {ReactNode, useEffect, useState} from "react";
import {observer} from "mobx-react-lite";
import {useAppStore} from "../providers/RootStoreProvider";
import {Avatar} from "./global/common";
import User from "../models/User";
import Room from "../models/Room";

type Props = {
    users: Array<User>
    andMore?: number
    confidential?: boolean
    room?: Room
    children?: ReactNode
}

const AvatarBag = observer(({users, andMore, confidential, room, children}: Props) => {
    const { user, usersLocation, getUsers, isInRoom } = useAppStore()

    if (andMore === undefined) {
        andMore = 1000
    }

    let max = andMore
    let userRendered = 0

    if (andMore && room && user && room.isSas() && !isInRoom(user.id, room.id)) {
        max = max - 1
    }

    return <Wrapper className={"avatar-bag"}>
        {users.map((user) => {
            if (userRendered < max) {
                userRendered++
                const className = [
                    "avatar",
                    user.is_me ? "me" : user.type
                ]

                return <Avatar key={user.id} className={className.join(" ")}>{!confidential && <img src={user.picture} alt={user.username}/>}</Avatar>
            }
        })}
        {andMore && max < users.length && <Avatar className={"avatar and-more"}>+{users.length - max}</Avatar>}
    </Wrapper>
})

const Wrapper = styled.div`
    display: flex;
    //margin: 25px;
    padding: 0;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    //background-color: #ccc;
`

export default AvatarBag
