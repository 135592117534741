import styled from "styled-components";

type ButtonType = {
    alert?: boolean
    technical?: boolean
    hangup?: boolean
}

export const Container = styled.div.attrs({
  className: 'container',
  })`
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  min-height: 100%;  
`
export const CenteredContainer = styled.div`
  width: 1200px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
  min-height: 100%;  
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Button = styled.button<ButtonType>`
    -webkit-font-smoothing: antialiased;
    margin: 15px auto;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0;
    height: 35px;
    min-width: 150px;
    background: ${props => {
        if (props.alert) {
            return "salmon"
        } else if (props.hangup) {            
            return "#cb2233"
        } else if (props.technical) {            
            return "var(--technical-color)"
        } else {
            return "#2386B6"
        }
}};
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-size: 14px;
    color: ${props => props.technical ? "#000" : "#fff"};
    font-weight: 500;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    justify-content: center;
    
    &[disabled] {
        background-color: #aaaaaa;
        cursor: not-allowed;
    }
`

export const Title = styled.h1`
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 100;
  font-size: 64px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: #FFFFFF;
  margin: 60px auto;
`

export const Header = styled.header`
  min-height: 500px;
  height: 50vh;
  position: relative;
  margin-top: -60px;
  z-index: 0;
  padding-top: 60px;
  font-size: 28px;
  text-shadow: 0px 1px 4px rgb(0 0 0 / 30%), 0px 2px 13px rgb(0 0 0 / 30%), 0px 3px 23px rgb(0 0 0 / 30%);
  
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    //background-image: url('/img/cover/music-purple-crowd-concert-audience-rave-festival-stage-performance-nightclub-performing-arts-rock-concert-atmosphere-of-earth-145331.jpg');
    background-size: cover;
    background-position: center center;
    //backdrop-filter: grayscale(1);
    filter: grayscale(1) contrast(1.4);
  }
  

    h1 {
      font-family: 'Roboto',sans-serif;
      font-style: normal;
      font-weight: 500;
      background-color: #00000044;
      padding: 0 25px;
    }
  
    .logo {
        background-color: #00000044;
    }
`

export const Avatar = styled.div`
    box-sizing: border-box;
    display: flex;
    height: 42px;
    width: 42px;    
    border: none;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: darkgrey;

    &.me {
        border: 4px solid #FA6D6D;
    }
    
    &.staff {
        border: 4px solid #5C2CAD;
    }
    
    &.admin {
        border: 4px solid var(--technical-color);
    }
    
    img {
        width: 100%;
    }
    
    &.and-more {
        background-color: transparent;
        margin-left: 8px;
        font-size: 14px;
        //opacity: 0;
        //
        //&.and-more-visible {
        //    opacity: 1;
        //    transition-delay: 200ms;
        //}
    }
`
