import {JsonRoom} from "../interfaces/room";
import {JsonEvent} from "../interfaces/event";
import moment from "moment";
import User from "./User";

export const SAS = "sas"
export const WORK = "room"
export const CONFERENCE = "conference"
export const OPENSPACE = "open-space"
export const SPORT = "sport"
export const CINEMA = "cinema"
export const STAFF = "staff"

export default class Room {
        id: number
        name: string = ""
        x: number = 0
        y: number = 0
        available: boolean = false
        type: string = ""
        doorPosition?: string
        hasVideo: boolean = false
        theme: string = "light"
        hasWaitingList: boolean = false
        confidential: boolean = false
        template: string | null = null
        displayName: boolean
        liveEvent: JsonEvent | null
        currentEvent: JsonEvent | null
        nextEvent: JsonEvent | null
        callStartAt: moment.Moment | null
        moderator: number | null
        canJoinIds: Array<number> = []
        slug: string

    constructor(data: JsonRoom) {
        this.id = data.id
        this.name = data.name
        this.x = data.x
        this.y = data.y
        this.available = data.available
        this.type = data.type
        this.doorPosition = data.doorPosition
        this.hasVideo = data.hasVideo
        this.theme = data.theme
        this.hasWaitingList = data.hasWaitingList
        this.confidential = data.confidential
        this.template = data.template
        this.displayName = data.displayName
        this.liveEvent = data.liveEvent
        this.currentEvent = data.currentEvent
        this.nextEvent = data.nextEvent
        this.callStartAt = data.callStartAt ? moment(data.callStartAt) : null
        this.moderator = data.moderator
        this.canJoinIds = data.canJoinIds
        this.slug = data.slug
    }

    toJson (): JsonRoom {
        return {
            id: this.id,
            name: this.name,
            x: this.x,
            y: this.y,
            available: this.available,
            type: this.type,
            doorPosition: this.doorPosition,
            hasVideo: this.hasVideo,
            theme: this.theme,
            hasWaitingList: this.hasWaitingList,
            confidential: this.confidential,
            template: this.template,
            displayName: this.displayName,
            liveEvent: this.liveEvent,
            currentEvent: this.currentEvent,
            nextEvent: this.nextEvent,
            callStartAt: this.callStartAt ? this.callStartAt.toString() : null,
            moderator: this.moderator,
            canJoinIds: this.canJoinIds,
            slug: this.slug,
        }
    }

    isModerator (user: User): boolean {
        return this.moderator === user.id
    }

    get liveEventState (): string {
        if (this.currentEvent?.id === this.liveEvent?.id) {
            return 'live'
        } else if (this.currentEvent && this.currentEvent.endedAt !== null) {
            return 'over'
        } else {
            return 'late'
        }
    }

    get capacity (): number {
        switch (this.type) {
            case SAS:
                return 1
            case STAFF:
                return 6
            case WORK:
                return 12
            case CONFERENCE:
                return 24
            case CINEMA:
                return 12
            case SPORT:
                return 16
            default:
                return 48
        }
    }

    isStaff (): boolean {
            return this.type === STAFF
    }

    isSas (): boolean {
            return this.type === SAS
    }
}
