import {useAppStore} from "../../../providers/RootStoreProvider";
import Room from "../../../models/Room";
import styled from "styled-components";

type ModalProps = {
    room: Room
}

const ConstructionModal = ({room}: ModalProps) => {
    const { user } = useAppStore()
    if (!user) {
        return <></>
    }

    return <Wrapper>
        <img src="/img/construction.png" alt="Under construction"/>
        <p>This room is under construction</p>
    </Wrapper>
}

const Wrapper = styled.div`
    img {
        width: 100%;
        opacity: 0.25;
    }
    
    p {
        font-style: italic;
    }
`

export default ConstructionModal



