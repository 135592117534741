import React from 'react';
import {Outlet, Navigate} from "react-router-dom";
import {useAppStore} from "../../providers/RootStoreProvider";
import {observer} from "mobx-react-lite";
import styled from "styled-components";
import NavBar from "../Layout/NavBar";
import SupportButton from "../Layout/SupportButton";

type Props = {
    secure: boolean
}

const SecureRoute = observer(({secure}: Props) => {
    const {authenticated} = useAppStore()

    const  isAuthenticated = () => {
        return authenticated
    }
    
    const redirectUrl = () => {
        if (secure) {
            return '/login' // unAuth default route
        } else {
            return '/' // auth default route
        }
    }

    const content = isAuthenticated() ? <>
            <NavBar />
            <SupportButton />
            <AppContent>
                <Outlet />
            </AppContent>
       </> : <Outlet />

    return ((isAuthenticated() && secure) || (!isAuthenticated() && !secure)) ?
        content
       : <Navigate to={redirectUrl()} />
})

const AppContent = styled.div`
    //background-color: red;
    min-height: 100%;
`

export default SecureRoute;
