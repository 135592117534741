import { useEffect } from "react";

function usePressKey(data: string|object, callback: Function, preventDefault: boolean = false) {
    if (typeof data === "string") {
        data = {
            code: data
        };
    }
    const defaultValues = {
        ctrlKey: false,
        shiftKey: false,
        altKey: false
    };
    data = { ...defaultValues, ...data };

    function handlePressKey(event: KeyboardEvent): void {
        const eventParams = {
            ctrlKey: event.ctrlKey,
            shiftKey: event.shiftKey,
            altKey: event.altKey,
            code: event.code
        };
        if (JSON.stringify(data) === JSON.stringify(eventParams)) {
            if (preventDefault) {
                event.preventDefault();
            }
            callback.call(null);
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", handlePressKey);

        return () => {
            document.removeEventListener("keydown", handlePressKey);
        };
    });
}

export default usePressKey;
