import Room, {SAS, STAFF} from "../models/Room";
import AppStore from "../stores/AppStore";
import {useAppStore} from "../providers/RootStoreProvider";
import User from "../models/User";

interface VoterInterface {
    can: Function
}

class RoomVoter implements VoterInterface {
    appStore: AppStore

    constructor (store: AppStore) {
        this.appStore = store
    }

    can (room: Room, attribute: string) {
        const { user, inRoom, isInRoom, isWaitingFor, getUsers } = this.appStore
        if (!user || room.type === SAS) {
            return false
        }

        switch (attribute) {
            case "open-door":
                return this.canToggleDoor(user, room, isInRoom) && !room.available
            case "close-door":
                return this.canToggleDoor(user, room, isInRoom) && room.available
            case "toggle-door":
                return this.canToggleDoor(user, room, isInRoom)
            case "enter":
                if (isInRoom(user.id, room.id)) {
                    return false
                } else if (room.type === STAFF) {
                    return (user.hasRole("STAFF") && room.available) || user.hasRole("ADMIN")
                } else {
                    // const staffs = getUsers(room.id).filter(user => user.isStaff());
                    // return user.hasRole("ADMIN") || room.available || (user.hasRole("STAFF") && staffs.length === 0)
                    return user.hasRole("ADMIN") || room.available
                }
            case "leave":
                return isInRoom(user.id, room.id) || isWaitingFor(user.id, room.id)
            case "wait":
                return !!room.nextEvent && !isWaitingFor(user.id, room.id)
            case "stop-wait":
                return isWaitingFor(user.id, room.id)
            case "wait-staff":
                return !isWaitingFor(user.id, room.id) && !isInRoom(user.id, room.id) && user.hasRole("STUDENT") && room.available
            case "enter-staff":
                return !inRoom() && (user.hasRole("STAFF") || user.hasRole("ADMIN"))
            case "toggle-staff-door":
                return isInRoom(user.id, room.id) && (user.hasRole("STAFF") || user.hasRole("ADMIN"))
            case "accept-waiter":
                return isInRoom(user.id, room.id) && (user.hasRole("STAFF") || user.hasRole("ADMIN"))
            case "refuse-waiter":
                return isInRoom(user.id, room.id) && (user.hasRole("STAFF") || user.hasRole("ADMIN"))
            case "kick":
                return isInRoom(user.id, room.id) && user.hasRole("ADMIN")
            case "end-meeting":
                return false
        }

        return false
    }

    private canToggleDoor(user: User, room: Room, isInRoom: (user: (number | null), room: number) => boolean) {
        // return (user.hasRole("STAFF") || user.hasRole("ADMIN") || room.isModerator(user)) && isInRoom(user.id, room.id) && room.doorPosition;
        return (user.hasRole("STAFF") || user.hasRole("ADMIN") || room.isModerator(user));
    }
}

function useVoter() {
    return {
        roomVoter: new RoomVoter(useAppStore())
    }
}

export default useVoter;
