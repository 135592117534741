import styled from "styled-components";
import {ReactNode} from "react";
import AvatarBag from "../AvatarBag";
import {useAppStore} from "../../providers/RootStoreProvider";
import Room from "../../models/Room";

type Props = {
    room: Room
    opened: boolean
    children?: ReactNode
}

const Door = ({room, opened, children}: Props) => {
    const { getWaitingUsers } = useAppStore()
    const users = getWaitingUsers(room.id)

    let classes = ['door', room.doorPosition]
    if (!opened) {
        classes.push('closed')
    }
    if (users.length > 0) {
        classes.push('with-users')

        if (room.isStaff()) {
            classes.push('door-staff with-'+users.length+'-users')
        }
    }

    return <Wrapper className={classes.join(' ')}>
        <AvatarBag users={users} andMore={room.isStaff() ? 1 : 3} />
    </Wrapper>
}


const Wrapper = styled.div<{position?: string}>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90px;
    height: 10px;
    background-color: inherit;
    transition-duration: 300ms;
    transform: translate(0, 50%);
    
    .avatar-bag {
        z-index: 100;
    }

    &:before {
        content: "";
        position: absolute;
        background-color: #F5F6F7;
        width: 100%;
        height: 23px;
        top: 50%;
        z-index: -1;
        transition-duration: 300ms;
    }
    
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: inherit;
        border-radius: inherit;
        z-index: 10;
    }

    &.closed {
        background-color: #8DA4A1 !important;

        &:before {
            //transition-delay: 100ms;
            height: 0;
        }
    }

    &.with-users {
        height: 30px;
        border-radius: 15px;

        &.door-staff {
            .avatar-bag {
                flex-wrap: nowrap;
            }

            &.with-1-users {
                width: 30px;
            }

            &.with-2-users {
                width: 60px;
            }
        }
    }

    &.top {
        top: -23px;
        transform: translate(0, -50%);

        &.closed {
            top: 0;
        }
    }

    &.bottom {
        bottom: -23px;
        
        &:before {
            top: auto;
            bottom: 50%;
        }

        &.closed {
            bottom: 0;
        }
    }

    &.left {
        left: -68px;
        //width: 10px;
        //height: 90px;        
        transform: rotate(90deg);
                
        &:before {
            top: auto;
            bottom: 50%;
        }

        &.with-users {
            //width: 30px;
        }

        &.closed {
            left: -45px;
        }
    }

    &.bottom-left {
        left: -68px;
        //width: 10px;
        //height: 90px;
        bottom: 30%;
        margin-bottom: -40px;
        transform: rotate(90deg);
                
        &:before {
            top: auto;
            bottom: 50%;
        }

        &.with-users {
            //width: 30px;
        }

        &.closed {
            left: -45px;
        }
    }

    &.bottom-mid-left {
        left: -68px;
        //width: 10px;
        //height: 90px;
        bottom: 50%;
        margin-bottom: -40px;
        transform: rotate(90deg);
                
        &:before {
            top: auto;
            bottom: 50%;
        }

        &.with-users {
            //width: 30px;
        }

        &.closed {
            left: -45px;
        }
    }

    .avatar-bag {
        margin-left: -8px;
        margin-top: -8px;

        .avatar {
            width: 26px;
            height: 26px;
            border-width: 3px;
            margin-right: -8px;
            margin-bottom: -8px;
        }
    }
`
export default Door
