import styled from "styled-components";

const LogoImg = ({ className }: {className?: string}) => (
    <img className={className} src={"/img/logo.png"} alt={"La plateforme"} />
)

const Logo = styled(LogoImg)`
    width: 200px;    
`

export default Logo
