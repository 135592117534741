import {IconName} from "@fortawesome/free-regular-svg-icons";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

export const solid = (name: IconName): IconProp => {
    return ["fas", name]
}

export const regular = (name: IconName): IconProp => {
    return ["far", name]
}
