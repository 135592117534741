import React, {useEffect, useState} from 'react';
import {observer} from "mobx-react-lite";
import {MessagePayload} from "../../stores/AppStore";
import moment from "moment";
import {useAppStore} from "../../providers/RootStoreProvider";
import {Button} from "../global/common";
import styled from "styled-components";

type Props = {
    message: MessagePayload
}

const Invitation = observer(({message}: Props) => {
    const {getRoom, moveUser, user, clearMessage} = useAppStore()
    const [timeLeft, setTimeleft] = useState<string>("")
    const roomId = parseInt(message.room)

    useEffect(() => {
        updateTimer()
        const interval = setInterval(updateTimer, 1000)
        return () => clearInterval(interval);
    }, [])

    const handleJoin = () => {
        clearMessage()
        if (user) {
            moveUser(user.id, roomId)
        }
    }

    const updateTimer = () => {
        const duration = moment.duration(moment(message.expiration).diff(moment()))
        const roomDuration = duration.minutes().toString().padStart(2, "0") + ":" + duration.seconds().toString().padStart(2, "0")
        if (duration.seconds() <= 0) {
            clearMessage()
        }
        setTimeleft(roomDuration)
    }

    return <>
        <Header>{getRoom(roomId).name} - Waiting Line</Header>
        <div>{message.message}</div>
        <Timer>{timeLeft}</Timer>
        <Button onClick={handleJoin}>Join the office</Button>
    </>
});

const Header = styled.div`
    margin-bottom: 15px;
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
`

const Timer = styled.div`
    margin-top: 10px;
`

export default Invitation;
