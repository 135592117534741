import styled from 'styled-components'
import User from "../../models/User";
import {Avatar} from "../global/common";
import Room from "../../models/Room";
import {useAppStore} from "../../providers/RootStoreProvider";
import {ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "../../helpers/fontawesome";
import useVoter from "../../hooks/useVoter";

type Props = {
    user: User
    room: Room
    theme: string
    withActions?: boolean
}

const UserItemList = ({user, room, theme, withActions}: Props) => {
    const {user: currentUser, isInRoom, isWaitingFor, kick, accept, refuse, canJoinRoom} = useAppStore()
    const { roomVoter } = useVoter()

    const handleKick = () => {
        if (window.confirm("Are you sure to want to kick "+user.username+"?" )) {
            kick(user.id)
        }
    }

    const handleAccept = () => {
        if (!canJoin) {
            accept(room.id, user.id)
        }
    }

    const handleRefuse = () => {
        if (!canJoin) {
            refuse(room.id, user.id)
        }
    }
    const classes = [
        user.is_me ? "me" : user.type,
        theme
    ]

    const actions: Array<ReactNode> = []
    const canJoin = canJoinRoom(user.id, room.id)

    if (currentUser?.id !== user.id && withActions) {
        if (isInRoom(user.id, room.id) && roomVoter.can(room, 'kick')) {
            actions.push(<Action className="action" key={"kick-"+user.id} title="Kick" onClick={handleKick}><FontAwesomeIcon icon={solid("ban")} /></Action>)
        }
        if (isWaitingFor(user.id, room.id) && roomVoter.can(room, 'accept-waiter')) {
            actions.push(<Action disabled={canJoin} className="action" key={"accept-"+user.id} title={"Accept"} onClick={handleAccept}><FontAwesomeIcon icon={solid("check")} /></Action>)
        }
        if (isWaitingFor(user.id, room.id) && roomVoter.can(room, 'refuse-waiter')) {
            actions.push(<Action disabled={canJoin} className="action" key={"refuse-"+user.id} title={"Refuse"} onClick={handleRefuse}><FontAwesomeIcon icon={solid("xmark")} /></Action>)
        }
    }

    return <Wrapper className={classes.join(" ")}>
        <Avatar><img src={user.picture} alt={user.username}/></Avatar>
        {actions.length > 0 && <Actions className={"actions"}>
            {actions.map(action => action)}
        </Actions>}
        <UserInfos className={"user-infos"}>
            <Identity>{user.username}</Identity>
            <Job>{user.title}</Job>
        </UserInfos>
    </Wrapper>
}

const Wrapper = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    background-color: #FFF;
    border-radius: 21px;
    margin: 10px 0;
    align-items: center;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    border: 1px solid #183139;

    &.dark {
        background-color: #183139;
        border: none;
        color: #FFF;
    }

    &.in-room {
        background-color: #2D4853;
        border: none;
        color: #FFF;
    }

    &.me {
        background-color: #FA6D6D;
        border: none;
        color: #FFF;
        //margin-bottom: 25px;
    }
    
    &.staff {
        background-color: #5C2CAD;
        border: none;
        color: #FFF;
    }
    
    &.admin {
        background-color: var(--technical-color);
        border: none;
        color: #183139;
    }
    
    .actions {
        visibility: hidden;
    }
    
    &:hover {
        .actions {
            visibility: visible;
            
            + .user-infos {
                visibility: hidden;
            }
        }
        
    }
`

const UserInfos = styled.div`
    margin-left: 10px;
`
const Identity = styled.div`
`
const Job = styled.div`
    font-style: italic;
    font-size: 13px;
`
const Actions = styled.div`
    position: absolute;
    right: 0;
    display: flex;
`
const Action = styled.div<{disabled?: boolean}>`
    cursor: ${props => props.disabled ? "not-allowed" : "pointer"};
    opacity: ${props => props.disabled ? "0.5" : "1"};
    font-size: 24px;
    border: 1px solid #fff;
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
`

export default UserItemList
