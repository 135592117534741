// @ts-nocheck
import React from 'react';
import './App.css';
import './app.scss'
import {RootStoreProvider} from "./providers/RootStoreProvider";
import {GoogleOAuthProvider} from "@react-oauth/google";
import styled from "styled-components"
import {Routes, Route} from "react-router-dom"
import Login from "./components/Login/Login";
import Home from "./components/Home/Home";
import Room from "./components/Page/Room";
import SecureRoute from "./components/Security/SecureRoute";
import Logout from "./components/Login/Logout";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { library } from '@fortawesome/fontawesome-svg-core'
import NotFound from "./components/Page/NotFound";
import SSE from "./components/Page/Debug/SSE";
import {GOOGLE_CLIENT_ID} from "./config/url";

const App = () => {
    library.add(fas, far)

    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            <RootStoreProvider>
                <div className="App">
                    <Routes>
                        <Route path={"/"} element={<SecureRoute secure={true} />}>
                            <Route path={"/"} element={<Home />} />
                            <Route path={"/logout"} element={<Logout />} />
                            <Route path={"/event/:id"} element={<Room />} />
                            <Route path={"/debug"} element={<SSE />} />
                            <Route path={"/*"} element={<NotFound />} />
                        </Route>
                        <Route path={"/"} element={<SecureRoute secure={false} />}>
                            <Route path={"/login"} element={<Login />} />
                        </Route>
                        {/* Ici les routes publiques */}
                    </Routes>
                    <div id="modal-root"></div>
                </div>

                {/*<MeetDemo />*/}
            </RootStoreProvider>
        </GoogleOAuthProvider>
    )
}

const MyCustomButton = styled.div`
    background-color: dodgerblue;
    color: white;
`

export default App;
